





































































import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'SearchResultsMainSkeleton',
  props: {
    areResultsAvailable: {
      type: Boolean,
      default: false
    }
  }
});
