



































































import {
  AisHitsPerPage,
  AisHits
} from 'vue-instantsearch';
import type { PropType } from '@nuxtjs/composition-api';
import { computed, defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import type { ProductGroupsItemType } from '~/types/cms/ProductGroupsItem';
import Pagination from '@/components/molecules/Search/Pagination.vue';
import { useInternalCms } from '@/composables';
import ProductListing from '~/components/organisms/Search/ProductListing.vue';
import { HITS_PER_PAGE_ITEMS, ALGOLIA_LOADING_STATE, ALGOLIA_STALLED_STATE } from '~/constants/algolia';
import { searchView } from '~/types/search/SearchView';
import { getCurrentInstance } from '~/helpers/getCurrentInstance';
import { COMPONENTS } from '~/constants/cms';
import { i18nToAlgoliaMainIndex } from '~/helpers/locales/i18nCurrentDetails';
import SearchResultsMainSkeleton
  from '~/components/molecules/SearchResultsSkeletons/SearchResultsMainSkeleton.vue';

export default defineComponent({
  name: 'SearchResultsInner',
  components: {
    AisHitsPerPage,
    AisHits,
    Pagination,
    ProductListing,
    NoResults: () => import(/* webpackChunkName: "NoResults" */
      '~/components/atoms/NoResults/NoResults.vue'),
    LazyHydrate,
    SearchResultsMainSkeleton,
    ProductGroupsSlider: () => import(/* webpackChunkName: "ProductGroupsSlider" */
      '~/cms/ProductGroupsSlider/ProductGroupsSlider.vue')
  },
  props: {
    categoryFilter: {
      type: String,
      default: ''
    },
    hits: {
      type: Array,
      required: true
    },
    query: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    productGroupItems: {
      type: Array as PropType<Array<ProductGroupsItemType>>,
      default: () => []
    },
    productGroupItemsTextColor: {
      type: String,
      default: 'text-EXPONDO-black'
    },
    productGroupItemsBackgroundColor: {
      type: String,
      default: 'bg-EXPONDO-grey-5'
    }
  },
  setup(props) {
    const root = getCurrentInstance();
    const { $i18n } = root;

    const {
      loadComponent: departmentBlockSearch,
      internalCmsContent: departmentBlockContent,
      canShowContent
    } = useInternalCms(COMPONENTS.SHOP_BY_DEPARTMENT.SLUG);

    const showSkeletonLoader = computed(() => {
      return props.status === ALGOLIA_STALLED_STATE || props.status === ALGOLIA_LOADING_STATE;
    });

    const listType = ref<string>(searchView.Grid);

    onMounted(() => {
      departmentBlockSearch();
    });

    const shouldShowProductGroupSlider = computed(() => {
      return props.productGroupItems.length > 0;
    });

    return {
      indexName: i18nToAlgoliaMainIndex($i18n),
      HITS_PER_PAGE_ITEMS,
      listType,
      departmentBlockContent,
      canShowContent,
      showSkeletonLoader,
      shouldShowProductGroupSlider
    };
  }
});
