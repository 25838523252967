var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SearchResultsTopSkeleton',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSkeletonLoader),expression:"showSkeletonLoader"}]}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showSkeletonLoader),expression:"!showSkeletonLoader"}],staticClass:"search-results__top mt-4 mb-12",class:{'flex-wrap items-center': _vm.isCampaignAvaliable}},[(_vm.isCampaignAvaliable)?_c('div',{staticClass:"basis-full grow block w-full lg:w-1/2 lg:basis-auto"},[_c('render-content',{staticClass:"h-36 lg:h-48",attrs:{"content":_vm.campaignBanner}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"search-results__reminder grow lg:grow-0 px-4 lg:px-0",class:{
        'order-last lg:order-first': _vm.categoryFilter,
        'lg:order-first': _vm.isCampaignAvaliable
      }},[(_vm.isCampaignAvaliable)?_c('div',{staticClass:"lg:max-w-sm"},[_c('render-content',{attrs:{"content":_vm.campaignTitle}})],1):_vm._e(),_vm._v(" "),(_vm.description)?_c('p',{staticClass:"text-left"},[_vm._v("\n        "+_vm._s(_vm.description)+"\n      ")]):_vm._e(),_vm._v(" "),(!_vm.categoryFilter && _vm.isOnSearchPage)?_c('ais-stats',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var nbHits = ref.nbHits;
return (_vm.isCampaignAvaliable)?_c('p',{staticClass:"lg:text-left"},[_vm._v("\n          "+_vm._s(_vm.$t('product-count', { productCount: nbHits }))+"\n        ")]):_c('p',{},[_c('span',[_vm._v(_vm._s(_vm.$tc('Number of Search Results', nbHits, { count: nbHits })))]),_vm._v(" "),(_vm.query)?_c('span',{staticClass:"font-bold"},[_vm._v("\n            "+_vm._s(_vm.$t('Search Term:'))+" "+_vm._s(_vm.query)+"\n          ")]):_vm._e()])}}],null,true)}):_c('CategoryBreadcrumbs')],1),_vm._v(" "),(_vm.hits.length > 0)?_c('div',{staticClass:"search-results__toolboxes w-full lg:w-1/2 items-center leading-7 px-4",class:{'lg:w-auto lg:order-last lg:px-0': _vm.isCampaignAvaliable}},[_c('div',{staticClass:"pr-2.5 w-1/2 lg:hidden"},[_c('button',{staticClass:"search-results__filter-toolbox--mobile border font-semibold w-full",on:{"click":_vm.onFilterClick}},[_c('ais-stats',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var instantSearchInstance = ref.instantSearchInstance;
return _c('span',{},[_vm._v("\n              "+_vm._s(_vm.$t("Filter"))+" ("+_vm._s(_vm.getCurrentFiltersCount(instantSearchInstance.indexName, instantSearchInstance.renderState))+")\n            ")])}}],null,false,3621264513)})],1)]),_vm._v(" "),_c('div',{staticClass:"flex w-1/2 lg:w-auto"},[_c('span',{staticClass:"hidden lg:inline text-rights self-center whitespace-nowrap"},[_vm._v("\n          "+_vm._s(_vm.$t("Sort by"))+":\n        ")]),_vm._v(" "),_c('ais-sort-by',{staticClass:"pl-2.5 w-full",attrs:{"items":_vm.sortingConfig}})],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }