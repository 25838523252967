var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ais-configure',{attrs:{"filters":_vm.categoryFilter,"hitsPerPage":_vm.hitsPerPageConfiguration,"clickAnalytics":true}}),_vm._v(" "),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_results = ref.results;
var hits = ref_results.hits;
var query = ref_results.query;
var status = ref.status;
return [_c('SearchResultsTop',{attrs:{"hits":hits,"query":query,"status":status,"category-filter":_vm.categoryFilter,"description":_vm.description}})]}}])}),_vm._v(" "),_c('div',{staticClass:"flex gap-2"},[_c('FilterModal',{staticClass:"lg:w-80"}),_vm._v(" "),_c('ais-state-results',{staticClass:"w-full",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_results = ref.results;
var hits = ref_results.hits;
var query = ref_results.query;
var status = ref.status;
return [_c('SearchResultsInner',{attrs:{"hits":hits,"query":query,"status":status,"category-filter":_vm.categoryFilter,"product-group-items":_vm.productGroupItems,"product-group-items-text-color":_vm.productGroupItemsTextColor,"product-group-items-background-color":_vm.productGroupItemsBackgroundColor}})]}}])})],1),_vm._v(" "),_c('LazyHydrate',{attrs:{"never":""}},[_c('VirtualSortBy',{attrs:{"items":_vm.sortingConfig}})],1),_vm._v(" "),_c('LazyHydrate',{attrs:{"never":""}},[_c('VirtualRangeInput',{attrs:{"attribute":_vm.ALGOLIA_ATTRIBUTE.PRICE}})],1),_vm._v(" "),_c('LazyHydrate',{attrs:{"never":""}},[_c('VirtualRatingMenu',{attrs:{"attribute":_vm.ALGOLIA_ATTRIBUTE.AVG_RATING,"max":_vm.MAX_STARS}})],1),_vm._v(" "),_c('LazyHydrate',{attrs:{"never":""}},[_c('VirtualPagination')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }