





























































































import {
  AisStats,
  AisSortBy
} from 'vue-instantsearch';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { useUiState, useDisplayCampaignInfo, useIsPage } from '@/composables';
import { ALGOLIA_LOADING_STATE, ALGOLIA_STALLED_STATE } from '~/constants/algolia';
import { getCurrentFiltersCount } from '~/helpers/algolia/getCurrentFiltersCount/getCurrentFiltersCount';
import { getAlgoliaSortingConfiguration } from '~/helpers/algolia/getAlgoliaSortingConfiguration';
import SearchResultsTopSkeleton
  from '~/components/molecules/SearchResultsSkeletons/SearchResultsTopSkeleton.vue';

export default defineComponent({
  name: 'SearchResultsInner',
  components: {
    CategoryBreadcrumbs: () => import(/* webpackChunkName: "CategoryBreadcrumbs" */
      '~/components/molecules/Category/CategoryBreadcrumbs/CategoryBreadcrumbs.vue'),
    AisStats,
    AisSortBy,
    SearchResultsTopSkeleton
  },
  props: {
    categoryFilter: {
      type: String,
      default: ''
    },
    hits: {
      type: Array,
      required: true
    },
    query: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { i18n } = useVSFContext();
    const { toggleFilterModal } = useUiState();

    const { isOnSearchPage } = useIsPage();

    const showSkeletonLoader = computed(() => {
      return props.status === ALGOLIA_STALLED_STATE || props.status === ALGOLIA_LOADING_STATE;
    });

    const {
      showCampaignInfo,
      campaignData
    } = useDisplayCampaignInfo();

    const campaignBanner = computed(() => campaignData.value?.banner || null);
    const campaignTitle = computed(() => campaignData.value?.title || null);

    const isCampaignAvaliable = computed(() => showCampaignInfo.value && campaignBanner.value && campaignTitle.value);

    const onFilterClick = () => {
      toggleFilterModal();
    };

    return {
      campaignData,
      sortingConfig: getAlgoliaSortingConfiguration(i18n),
      onFilterClick,
      getCurrentFiltersCount,
      showSkeletonLoader,
      isCampaignAvaliable,
      campaignBanner,
      campaignTitle,
      isOnSearchPage
    };
  }
});
